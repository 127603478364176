import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { MEGAMENU_CMS_PATH_EXTENSION } from 'helpers/constants/aafes';
import { fetchApiHub, revalidateOptions, useCart } from 'frontastic';
import Accordion from './accordion';
import MobileAccordion from './mobileAccordion';

function MegaMenu({
  megaMenu,
  setcloseMenu,
  leftColumn,
  rightColumn,
  findStore,
  militaryStarData,
  militaryStarApply,
  militaryStarParentURL,
  setmobileMenu,
  setStoresList,
  storeList,
  searchInput,
  setSearchInput,
  isPreviewDate,
  islargeDesktop,
  additionalPadding,
  setResetDefaultStore,
}) {
  const { studioConfig } = useCart();
  const [megaMenuCMSBasePath, setMegaMenuCMSBasePath] = useState(null);
  const [megaMenuCategorySuffix, setMegaMenuCategorySuffix] = useState(null);
  useEffect(() => {
    if (studioConfig?.megaMenuCMSBasePath) {
      try {
        const megaMenuCMSKey = studioConfig?.megaMenuCMSBasePath;
        setMegaMenuCMSBasePath(megaMenuCMSKey);
      } catch (error) {
        console.error('Error Parsing megaMenuCMSTemlateKey:', error);
      }
    }
    if (studioConfig?.megaMenuCategorySuffix) {
      try {
        const megaMenuCategorySuffix = studioConfig?.megaMenuCategorySuffix;
        setMegaMenuCategorySuffix(megaMenuCategorySuffix);
      } catch (error) {
        console.error('Error Parsing megaMenuCMSTemlateKey:', error);
      }
    }
  }, [studioConfig]);
  const formatCategoryName = (name: string) => {
    return name.toLowerCase().replace(/[, &]/g, '-').replace(/--+/g, '-').replace(/^-|-$/g, '');
  };
  const menuCategories = megaMenu?.categoryData?.dataSource?.root?.children;

  const megaMenuAssetpathObject: Record<string, string> = menuCategories?.reduce((acc, item) => {
    const formattedCategoryName = formatCategoryName(item?.categoryName || '');
    const categorySuffix = megaMenuCategorySuffix && megaMenuCategorySuffix?.replace('SUFFIX', formattedCategoryName);
    acc[item?.categoryName] = `${megaMenuCMSBasePath}${categorySuffix}${MEGAMENU_CMS_PATH_EXTENSION}`;
    return acc;
  }, {});
  const formattedArray = Object?.values(megaMenuAssetpathObject)
    ?.map((item) => encodeURIComponent(item))
    ?.join('%2C');
  const { data } = useSWR(
    megaMenuCMSBasePath ? `/action/cms/getTemplates?assetPath=${encodeURIComponent(formattedArray)}` : null,
    fetchApiHub,
    revalidateOptions,
  );

  return (
    <>
      <section
        className={`z-11 absolute hidden h-[100vw] w-[calc(100%_-_50px)] max-w-[calc(1440px_-_50px)] md:block ${
          isPreviewDate && (islargeDesktop || additionalPadding) ? 'top-[112px]' : 'top-[66px]'
        }`}
      >
        <section className="block md:grid md:grid-cols-5">
          <Accordion
            menuCategories={menuCategories}
            closeMenu={setcloseMenu}
            megaMenuAssetpathObject={megaMenuAssetpathObject}
            templates={data}
          />
        </section>
      </section>

      <section className="fixed left-0 top-0 z-20 block h-[100vh] w-11/12 overflow-y-auto bg-white md:hidden">
        <section
          className="fixed left-0 top-0 z-10 h-[100vh] w-full bg-black-900 opacity-70"
          onClick={() => setmobileMenu(false)}
        ></section>
        <MobileAccordion
          menuCategories={menuCategories}
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          findStore={findStore}
          closeMenu={setmobileMenu}
          militaryStarData={militaryStarData}
          militaryStarApply={militaryStarApply}
          militaryStarParentURL={militaryStarParentURL}
          setStoresList={setStoresList}
          storeList={storeList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setResetDefaultStore={setResetDefaultStore}
          megaMenuAssetpathObject={megaMenuAssetpathObject}
          templates={data}
        />
      </section>
    </>
  );
}

export default MegaMenu;
